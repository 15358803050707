<template>
  <div class="row justify-content-center h-100">
    <div class="col-xl-12 h-100">
      <transition mode="out-in" name="slide-fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransferPage",
};
</script>

<style scoped></style>
